.career{
  .img-carrer-elephant{
    max-width: 50%;
    @media (max-width: 992px) {
      max-width: 70%;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  .picto-eleph{
    max-width: 15.5rem;
  }
  .container-grandir-ensemble{
    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 0;
      .grandir-ensemble-txt{
        order: 2;
        margin-top: 2rem;
      }
    }
  }
  .container-career-video-youtube{
    position: relative;
    height: 45rem;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .patoune-media-right{
    position: relative;
    &:before{
      content: url($imagesPath + 'structure/motif2.svg');
      position: absolute;
      top: -20%;
      right: 12%;
      width: 15.5rem;
      z-index: 10;
      @media (max-width: 768px) {
        width: 10rem;
        top: -8%;
      }
    }
  }
  .patoune-media-left{
    position: relative;
    &:before{
      content: url($imagesPath + 'structure/motif2.svg');
      position: absolute;
      top: -20%;
      left: 12%;
      width: 15.5rem;
      z-index: 10;
      @media (max-width: 768px) {
        width: 10rem;
        top: -8%;
      }
    }
  }
}
.expertises{
  .img-expertises-elephant{
    max-width: 50%;
    @media (max-width: 992px) {
      max-width: 70%;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  .expertises-schemas{
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .all-techno{
    margin-top: 3rem;
    margin-bottom: 3rem;
    .front-end, .back-end{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
    img{
      width: 12rem;
      margin: 1rem 2rem;
    }
  }
  .container-grandir-elephant{
    @media (max-width: 768px) {
      flex-direction: column;
      .grandir-elephant-txt{
        margin-top: 2rem;
      }
    }
  }
}
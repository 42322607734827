.value-elephant{
  .picto-eleph{
    max-width: 25rem;
  }
  .container-valeur-video-youtube{
    position: relative;
    height: 35rem;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 35rem;
      padding: 0 2rem;
    }
  }
  .section-teams{
    background-image: url($imagesPath + 'structure/fond-equipe.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .container-teams{
      position: relative;
      padding: 21rem 0;
      @media (max-width: 768px) {
        padding: 14rem 0;
      }
      .flex-nowrap{
        @media (max-width: 768px) {
          flex-wrap: wrap!important;
        }
      }
    }
  }
  .background-head-elephant{
    background: url('../../images/structure/elephant-fond.svg') no-repeat right -25% center;
    background-size: 45%;
  }
  
  .background-head-elephant-grey{
    background: url('../../images/structure/elephant-gris-fond.svg') no-repeat right -25% top 20%;
    background-size: 35%;
  }
  .value-elephant-block{
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
      .value-elephant-block-img{
        text-align: center;
      }
      .title-h3{
        text-align: center;
      }
    }
  }
  .galerie-value{
    .galerie-part{
      display: flex;
      flex-direction: column;
      img{
        margin: 2.5rem 0;
        @media (max-width: 768px) {
          margin: 1rem 0;
        }
      }
    }
    .galerie-part-left{
      align-items: flex-end;
      padding-right: 1rem;
      img{
        &:last-child{
          width: 43.7%;
        }
      }
    }
    .galerie-part-right{
      align-items: flex-start;
      padding-left: 3.5rem;
      @media (max-width: 768px) {
        padding-left: 1.5rem;
      }
      img{
        &:first-child{
          width: 52.1%;
        }
        &:last-child{
          width: 64.6%;
        }
      }
    }
  }
  .swiper-container-value-elephant{
    overflow: hidden;
    .swiper-wrapper .swiper-slide-active {
      margin-top: 6rem;
      @media (max-width: 480px) {
        margin-top: 0;
        margin-bottom: 2rem;
      }
    }
    .swiper-wrapper .swiper-slide-next {
      margin-top: 12rem;
      @media (max-width: 480px) {
        margin-top: 0;
        margin-bottom: 2rem;
      }
    }
    .swiper-slide {
      margin-top: 0;
      transition: 500ms;
      @media (max-width: 480px) {
        margin-bottom: 2rem;
      }
    }
  }
  .swiper-value-elephant-information{
    height: 5rem;
    width: 10%;
    margin: 0 auto;
    @media (max-width: 992px) {
      width: 15%;
    }
    @media (max-width: 480px) {
      width: 20%;
    }
    @media (max-width: 400px) {
      width: 22%;
    }
  }
}


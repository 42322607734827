html{
  font-size: 62.5%;
  @media (max-width: 1200px) {
    font-size: 52.5%;
  }
  @media (max-width: 768px) {
    font-size: 42.5%;
  }
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  @include font-size(1.7);
  background-color: $color-default-background;
  color: $color-white;
  overflow-x: hidden;
  width: 100%;
}

*, *:after, *:before  {
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding:0;
  margin:0;
}

section{
  overflow: hidden;
}

a{
  color: $color-white;
  &:hover{
    text-transform: none;
    text-decoration: none;
    color: $color-white;
  }
}

p{
  margin-bottom: 0;
}

.main-container ul{
  list-style: none;
  padding-left: 2.5rem;
  margin: 2.5rem 0;
  li{
    margin-bottom: 2rem;
    color: $color-grey;
    display: flex;
    position: relative;
    &:before{
      display : inline-block;
      position: absolute;
      left: -2.5rem;
      top: 0.5rem;
      content: '';
      height : 0;
      width : 0;
      border-top : 1.7rem solid $color-green;
      border-right : 0.98rem solid transparent;
      border-left : 0.98rem solid transparent;
    }
  }
}

.title-h1{
  @include font-size(10);
  line-height: 9.5rem;
  font-family: 'PT Serif', serif;
  @media (max-width: 560px) {
    @include font-size(7);
    line-height: 6.5rem;
  }
}

.title-h1-alt{
  @include font-size(8);
  line-height: 7.5rem;
  font-family: 'PT Serif', serif;
  text-align: center;
  @media (max-width: 768px) {
    @include font-size(7);
    line-height: 6.5rem;
  }
  @media (max-width: 560px) {
    @include font-size(5);
    line-height: 4.5rem;
  }
  @media (max-width: 420px) {
    @include font-size(3);
    line-height: 2.5rem;
  }
}

.title-h2{
  @include font-size(6);
  line-height: 5.9rem;
  margin-bottom: 1.7rem;
  font-family: 'PT Serif', serif;
  @media (max-width: 560px) {
    @include font-size(5);
    line-height: 4.9rem;
  }
}

.title-h3{
  @include font-size(3);
  line-height: 2.9rem;
  margin-top: 1.7rem;
  font-family: 'PT Serif', serif;
}

.title-h3-alt{
  @include font-size(2.4);
  font-family: 'PT Serif', serif;
}

.title-h3-footer{
  @include font-size(2.4);
  font-family: 'PT Serif', serif;
}

.subtitle{
  @include font-size(1.1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-family: 'PT Serif', serif;
  line-height: 1rem;
}

.mt45{
  margin-top: 4.5rem;
}

.mtb-1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mtb-2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mtb-4{
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.pb-6{
  padding-bottom: 6rem;
}

.mtb-7{
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.mt-8{
  margin-top: 8rem;
}

.mb-12{
  margin-bottom: 12rem;
}

.pt-17{
  padding-top: 17rem;
}

.mt-18{
  margin-top: 18rem;
}

.mb-21{
  margin-bottom: 21rem;
}

.pt-22{
  padding-top: 22rem;
}

.pb-23{
  padding-bottom: 23rem;
}

.pb-26{
  padding-bottom: 26rem;
}

.mt-28{
  margin-top: 28rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
}

.pb-60{
  padding-bottom: 60rem;
}

.anchor {
  display: block;
  position: relative;
  top: -15rem;
  visibility: hidden;
}

.no-padding{
  padding-left: 0;
  padding-right: 0;
}

.color-green{
  color: $color-green;
  &:hover{
    color: $color-green;
  }
}
.color-white{
  color: $color-white;
  &:hover{
    color: $color-white;
  }
}
.color-grey{
  color: $color-grey;
  &:hover{
    color: $color-grey;
  }
}
.color-grey-black{
  color: $color-grey-black;
  &:hover{
    color: $color-grey-black;
  }
}

.pt-serif{
  font-family: 'PT Serif', serif;
  font-weight: 400;
  &.italic, .italic{
    font-style: italic;
  }
}

.poppins{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  &.semi-bolder, .semi-bolder{
    font-weight: 600;
  }
}

b,
strong {
  font-weight:700
}

.bg-grey{
  background-color: $color-grey;
}

.bg-grey-black{
  background-color: $color-grey-black;
}

.bg-green{
  background-color: $color-green;
}

.bg-white{
  background-color: $color-white;
}

.bg-default{
  background-color: $color-default-background;
}

.alert-success{
  background-color: transparent;
  border-color: transparent;
  padding-left: 0;
  color: $color-green;
  &:before{
    content: "";
    position: relative;
    width: 2rem;
    height: 2rem;
    background: url($imagesPath + 'pictos/checkmark-circle.svg') no-repeat center!important;
    display: inline-block;
    vertical-align: sub;
  }
}

ul.listing-sitemap{
  li::before{
    display: none!important;
  }
  li{
    a{
      color: $color-grey;
      &:hover{
        text-decoration: underline!important;
      }
    }
  }
}

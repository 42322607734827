.masque-vague{
  &:after{
    display: block;
    position: absolute;
    left: 0;right: 0;
    pointer-events: none;
    z-index: 2;
  }
  &.masque-vague-reverse-y{
    &:after{
      transform: scaleY(-1);
    }
  }
  &.masque-vague-reverse-x{
    &:after{
      transform: scaleX(-1);
    }
  }
  &.masque-vague-grey{
    &:after{
      content: url($imagesPath + 'structure/vague-grey.svg');
    }
  }
  &.masque-vague-green{
    &:after{
      content: url($imagesPath + 'structure/vague-green.svg');
    }
  }
  &.masque-vague-grey-black{
    &:after{
      content: url($imagesPath + 'structure/vague-grey-black.svg');
    }
  }
  &.masque-vague-white{
    &:after{
      content: url($imagesPath + 'structure/vague-white.svg');
    }
  }

  &.masque-vague-bottom{
    &:after{
      bottom: -8px;
      @media (max-width: 992px) {
        bottom: -7px;
      }
      @media (max-width: 768px) {
        bottom: -5px;
      }
    }
  }
  &.masque-vague-top{
    &:after{
      top: -8px;
      @media (max-width: 992px) {
        top: -7px;
      }
      @media (max-width: 768px) {
        top: -5px;
      }
    }
  }
}

.masque-vague-before{
  &:before{
    display: block;
    position: absolute;
    left: 0;right: 0;
    pointer-events: none;
  }
  &.masque-vague-before-reverse-y{
    &:before{
      transform: scaleY(-1);
    }
  }
  &.masque-vague-before-reverse-x{
    &:before{
      transform: scaleX(-1);
    }
  }
  &.masque-vague-before-grey{
    &:before{
      content: url('../../images/structure/vague-grey.svg');
    }
  }
  &.masque-vague-before-green{
    &:before{
      content: url('../../images/structure/vague-green.svg');
    }
  }
  &.masque-vague-before-grey-black{
    &:before{
      content: url('../../images/structure/vague-grey-black.svg');
    }
  }
  &.masque-vague-before-white{
    &:before{
      content: url('../../images/structure/vague-white.svg');
    }
  }

  &.masque-vague-before-bottom{
    &:before{
      bottom: -8px;
      @media (max-width: 992px) {
        bottom: -7px;
      }
      @media (max-width: 768px) {
        bottom: -5px;
      }
    }
  }
  &.masque-vague-before-top{
    &:before{
      top: -8px;
      @media (max-width: 992px) {
        top: -7px;
      }
      @media (max-width: 768px) {
        top: -5px;
      }
    }
  }
}
.show-news{
  .title-h1-alt{
    @media (max-width: 992px) {
      margin-top: 10rem;
    }
    @media (max-width: 650px) {
      font-size: 6rem;
      line-height: 5.5rem;
      padding: 0 5rem!important;
    }
    @media (max-width: 460px) {
      font-size: 4rem;
      line-height: 3.5rem;
      padding: 0 5rem!important;
    }
  }
  strong{
    font-weight: bold;
  }
  pre{
    background-color: $color-white;
    padding: 1rem;
  }
  .main-container{
    a{
      color: $color-green;
      text-decoration: underline;
      &:hover{
        color: $color-green;
        text-decoration: underline;
      }
    }
  }
}

.listing-news-linkedin{
  .juicer-feed{
    .feed-item.j-linkedin{
      &.j-linkedin{
        &:hover{
          cursor: pointer;
        }
        &.image-post{
          padding: 0;
          .j-post-container{
            padding: 12px;
          }
        }
      }
    }
  }
  ul{
    padding-left: 0;
    li{
      &:before{
        display: none;
      }
    }
  }
}
.swiper-button-next, .swiper-button-prev{
  width: 4rem;
  height: 4rem;
  background-color: $color-default-background;
  border-radius: 50%;

  &:before{
    content: '';
    background-image: url($imagesPath + 'structure/fleche1.svg');
    background-repeat: no-repeat;
    background-size: 40% auto;
    background-position: center;
    position: absolute;
    top: 0;left: 0;right: 0;bottom: 0;

  }
}

.swiper-pagination{
  color: $color-grey-black;
  font-family: 'PT Serif', serif;
  @include font-size(1.6);
  .swiper-pagination-current{
    @include font-size(2.4);
  }
}

.swiper-button-next {

}

.swiper-button-next::after {
  display: none;
}
.swiper-button-prev {
  transform: rotate(180deg);
}

.swiper-button-prev::after {
  display: none;
}
.homepage{
  .header-container{
    &:before{
      width: 45rem;
      @media screen and (max-width: 992px) {
        width: 25rem;
      }
    }
  }
  .background-head-elephant{
    background-image: url('../../images/structure/elephant-fond.svg');
    background-repeat: no-repeat;
    background-position: right -25% center;
    background-size: 45%;
  }
  .patoune-media-center{
    position: relative;
    &:before{
      content: url($imagesPath + 'structure/motif2.svg');
      position: absolute;
      top: -20%;
      right: 30%;
      width: 15.5rem;
      @media (max-width: 768px) {
        width: 10rem;
        top: -10%;
      }
    }
  }
  .green-container-homepage{
    position: relative;
    &:before{
      content: url($imagesPath + 'structure/motif1.svg');
      position: absolute;
      top: 5%;
      right: 17%;
      width: 15.5rem;
      z-index: 5;
      @media (max-width: 768px) {
        width: 10rem;
      }
    }
  }
  .container-grandir-elephant{
    margin-top: 9rem;
    margin-bottom: 7rem;
    @media (max-width: 768px) {
      flex-direction: column;
      .grandir-elephant-txt{
        margin-top: 2rem;
      }
    }
  }
  .section-last-offer{
    background-image: url($imagesPath + 'structure/fond-ile-de-nantes.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .container-last-offer{
    position: relative;
    padding: 21rem 0;
    @media (max-width: 768px) {
      padding: 14rem 0;
    }
    .flex-nowrap{
      @media (max-width: 768px) {
        flex-wrap: wrap!important;
      }
    }
  }
  .container-bloc-elephant-programme{
    flex-wrap: nowrap;
    @media (max-width: 574px) {
      flex-wrap: wrap;
      padding: 0 1.5rem;
    }
    .bloc-elephant-programme{
      position: relative;
      padding: 3.5rem 6.5rem 3rem;
      margin: 0 3rem;
      background-color: $color-default-background;
      transition: all ease-in-out 500ms;
      @media (max-width: 574px) {
        margin: 0 3rem 2rem;
      }
      &:hover{
        background-color: $color-green;
        .container-img{
          svg{
            .elephant {
              path {
                stroke: $color-white;
              }
            }
            .triangle{
              path{
                fill: $color-green-2;
              }
            }
          }
        }
        p{
          color: $color-white;
        }
      }
      a{
        position: absolute;
        top: 0;bottom: 0;right: 0;left: 0;
        z-index: 5;
      }
      .container-img{
        position: relative;
        width: 100%;
        @media (max-width: 574px) {
          max-width: 28rem;
        }
        path{
          transition: all ease-in-out 350ms;
        }
        img{
          display: flex;
          margin-bottom: 1.5rem;
          transition: all ease-in-out 500ms;
          &.img-default{
            opacity: 1;
          }
          &.img-hover{
            position: absolute;
            top: 0;bottom: 0;right: 0;left: 0;
            opacity: 0;
          }
        }
      }
      p{
        text-align: center;
        transition: all ease-in-out 500ms;
        @include font-size(3);
        color: $color-grey-black;
        font-family: 'PT Serif', serif;
      }
    }
  }
  .offer-block-homepage-container{
    @media (max-width: 574px) {
      padding: 0 1.5rem;
    }
    .offer-block{
      margin: 4.5rem 2rem 1.5rem;
      height: 25rem;
      width: 33%;
      @media (max-width: 768px) {
        margin-bottom: 2rem;
        width: 100%;
      }
      &:nth-child(2){
        margin-top: 8.5rem;
        @media (max-width: 768px) {
          margin-top: 2rem;
        }
      }
      &:nth-child(3){
        margin-top: 12.5rem;
        @media (max-width: 768px) {
          margin-top: 2rem;
        }
      }
    }
  }
  .section-news-homepage{
    .global-container-swiper-homepage{
      overflow: hidden;
      @media (max-width: 574px) {
        padding: 0 1.5rem;
      }
    }
    .swiper-container-homepage{
      .swiper-wrapper .swiper-slide-active {
        margin-top: 3rem;
        opacity: 1;
        @media (max-width: 768px) {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }
      .swiper-wrapper .swiper-slide-next {
        margin-top: 6rem;
        @media (max-width: 768px) {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }
      .swiper-slide {
        margin-top: 0;
        transition: 500ms;
        opacity: 0.2;
        @media (max-width: 768px) {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }
    }
    .swiper-homepage-information{
      height: 4.5rem;
      margin: 2rem auto 0;
      width: 150px;
    }
  }
}
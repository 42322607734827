.logo-full-no-sticky{
  position: absolute;
  top: 1.5rem;
  left: 6.5rem;
  z-index: 9999;
  width: 21rem;
  &.sticky-mode{
    z-index: 5;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.container-header-video-youtube{
  position: relative;
  height: 85rem;
  width: 100%;
  background: transparent;
  @media (max-width: 768px) {
    height: 55rem;
  }
  @media (max-width: 560px) {
    height: 42rem;
  }
  &:after{
    content: "";
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top: 0;bottom: 0;right: 0;left: 0;
  }
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .ytplayer-container{
    position: absolute;
    top: 0;
    z-index: -1;
  }
}
.header-container{
  position: relative;
  overflow: hidden;
  .header-elephant-anim{
    display: block;
    position: absolute;
    bottom: 4rem;
    left: 24rem;
    width: 50rem;
    pointer-events: none;
    z-index: 1;
    transform: rotate(-10deg);
    @media screen and (max-width: 992px) {
      width: 35rem;
      bottom: 2rem;
    }
    @media (max-width: 560px) {
      display: none;
    }
  }
  img.header-full-image{
    width: 100%;
    filter: brightness(50%);
    -webkit-filter: brightness(50%);
  }
  .text-header{
    position: absolute;
    top: 0;bottom: 0;left: 0;right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 9.5rem;
    .title-header{
      margin-top: 23rem;
      padding: 0;
      @media (max-width: 560px) {
        margin-top: 4rem;
      }
    }
    &.center-title{
      justify-content: flex-start;
      align-items: center;
      padding-left: 0;
      .title-header{
        margin-top: 14rem;
        padding: 0 10rem;
        @media (max-width: 991px) {
          margin-top: 7rem;
        }
        @media (max-width: 560px) {
          margin-top: 4rem;
        }
      }
    }
    .title-link{
      margin-top: 2.5rem;
    }
  }
}
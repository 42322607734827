$color-default-background: #f5f0ee;

$color-grey-white: #e6e6e6;
$color-grey: #7f7f7f;
$color-grey-black: #363636;
$color-black: #000000;
$color-green: #b9c20e;
$color-green-2: #a5ad05;
$color-white-green: #f2f4d2;
$color-white: #ffffff;
$color-blue: #009db3;


.offer-block{
  position: relative;
  padding: 5.5rem 3.5rem 7.5rem;
  background-color: $color-white;
  transition: all ease-in-out 500ms;
  height: 100%;
  &:hover{
    //background-color: $color-blue;
    .category, .title, .infos-comp p{
      color: $color-white;
    }
    .fake-plus{
      width: 100%;
      height: 100%;
    }
  }
  .category{
    position: relative;
    @include font-size(1.4);
    color: $color-grey-black;
    font-family: 'PT Serif', serif;
    transition: all ease-in-out 500ms;
    z-index: 2;
    &:before{
      display: inline-block;
      content: '';
      width: 1.4rem;
      height: 1.4rem;
      background-color: $color-green;
      border-radius: 50%;
      margin-right: 0.5rem;
      vertical-align: sub;
    }
  }
  .title{
    position: relative;
    @include font-size(2.4);
    color: $color-grey-black;
    font-family: 'PT Serif', serif;
    margin-top: 1.5rem;
    margin-bottom: 2.2rem;
    transition: all ease-in-out 500ms;
    z-index: 2;
  }
  .infos-comp p{
    position: relative;
    @include font-size(1.2);
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
    color: $color-grey;
    transition: all ease-in-out 500ms;
    z-index: 2;
    span{
      margin-right: 0.2rem;
    }
  }
  .link{
    position: absolute;
    bottom: 0;right: 0;left: 0;top: 0;
    z-index: 3;
  }
  .fake-plus{
    position: absolute;
    z-index: 1;
    bottom: 0;right: 0;
    display: flex;
    width: 6rem;
    height: 6rem;
    background-color: $color-blue;
    transition: all ease-in-out 500ms;
    span{
      color: $color-white;
      bottom: 2.1rem;
      position: absolute;
      right: 2.1rem;
    }
  }
}
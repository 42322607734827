//Override tarteaucitron

//Box base
#tarteaucitronAlertBig{
  background-color: $color-green!important;
  border-radius: 0!important;
}

div#tarteaucitronAlertBig::before{
  content: ''!important;
  background: url($imagesPath + 'pictos/cookie.png') no-repeat center!important;
  display: block!important;
  width: 51px!important;
  height: 51px!important;
  margin: 0 auto!important;
  background-size: cover!important;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert{
  font-family: 'PT Serif', serif!important;
}

#tarteaucitronAlertBig button.tarteaucitronAllow{
  background: $color-grey-black!important;
  color: $color-white!important;
  font-family: 'PT Serif', serif!important;
  padding: 13px 30px!important;
  font-style: italic!important;
  margin-left: 0!important;
  display: inline-flex!important;
  align-items: center!important;
}
#tarteaucitronAlertBig button.tarteaucitronAllow:first-letter{
  text-transform: uppercase!important;
}
#tarteaucitronAlertBig button .tarteaucitronCheck::before{
  content: ''!important;
  background:url($imagesPath + 'pictos/check.png') no-repeat center;
  background-size: cover;
  color: $color-white!important;
  margin-right: 5px!important;
  display: flex!important;
  width: 24px!important;
  height: 24px!important;
}

#tarteaucitronAlertBig button#tarteaucitronAllDenied2, #tarteaucitronAlertBig button#tarteaucitronCloseAlert{
  background: $color-white!important;
  color: $color-green!important;
  font-family: 'PT Serif', serif!important;
  padding: 13px 30px!important;
  margin-left: 0!important;
  font-style: italic!important;
  display: inline-flex!important;
  align-items: center!important;
}

#tarteaucitronAlertBig button#tarteaucitronAllDenied2:first-letter, button#tarteaucitronCloseAlert:first-letter{
  text-transform: uppercase!important;
}
#tarteaucitronAlertBig button .tarteaucitronCross::before{
  content: ''!important;
  background:url($imagesPath + 'pictos/refuser-vert.png') no-repeat center;
  background-size: cover;
  color: $color-green!important;
  margin-right: 5px!important;
  display: flex!important;
  width: 24px!important;
  height: 24px!important;
}

#tarteaucitronAlertBig button#tarteaucitronCloseAlert::before{
  content: ''!important;
  background:url($imagesPath + 'pictos/personnaliser-vert.png') no-repeat center;
  background-size: cover;
  color: $color-green!important;
  margin-right: 5px!important;
  display: flex!important;
  width: 24px!important;
  height: 24px!important;
}

#tarteaucitronAlertBig button#tarteaucitronPrivacyUrl{
  display: inherit;
  margin: 10px auto 0;
  text-decoration: underline;
  font-family: 'PT Serif', serif!important;
}

//Box advanced
#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected{
  background-color: $color-green!important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow{
  background-color: $color-green!important;
}
#tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronLine.tarteaucitronIsAllowed{
  border-color: $color-green!important;
}

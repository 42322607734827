.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: transparent;
  transition: all ease-in-out .5s;
  @media (max-width: 992px) {
    height: 8rem;
  }
  > .row{
    @media (max-width: 992px) {
      align-items: center;
    }
  }
  .main-logo-left {
    display: flex;
    align-items: center;
    margin-left: 4.3rem;
    img {
      &.sticky-logo {
        display: none;
      }
    }
  }
  .mobile-logo{
    display: none;
    @media (max-width: 992px) {
      display: flex;
      justify-content: center;
      > img {
        width: 13rem;
      }
    }
  }
  .main-nav-block {
    margin: 2rem 0 0;
    padding: 0 1.5rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    transition: all ease-in-out .5s;
    @media (max-width: 1300px) {
      justify-content: flex-end;
      margin-left: 7rem;
    }
    .navbar-collapse {
      flex-grow: 0;
      .sidebar-mobile{
        display: none;
        @media (max-width: 992px) {
          display: flex;
        }
      }
    }
    .dropdown-toggle::after{
      vertical-align: middle;
    }
    .link-item {
      padding: 1rem 2rem 1rem;
      @media (max-width: 992px) {
        padding: 1.4rem 0;
        width: 90%;
        text-align: center;
      }
      .dropdown-menu{
        min-width: 22rem;
        top: auto;
        margin-top: 1.3rem;
        padding: 2.2rem 2.5rem;
        background-color: $color-green;
        border: none;
        border-radius: 0;

        @media (max-width: 992px) {
          margin-top: 0;
          min-width: auto;
        }
        .link-title{
          @include font-size(1.4);

          @media (max-width: 992px) {
            text-align: center;
            &:hover{
              background-color: $color-grey-black;
            }
          }
        }
      }
      .nav-link {
        padding-right: 0;
        padding-left: 0;
      }
      .link-title {
        color: $color-white;
        transition: color 0.5s ease-in-out;
        @include font-size(1.8);
        @media (max-width: 992px) {
          color: $color-grey-black;
          @include font-size(2);
        }
      }
      &:after {
        content: "";
        display: block;
        width: 50%;
        transition: border-bottom 0.5s ease-in-out;
        border-bottom: 0.3rem solid transparent;
        @media (max-width: 992px) {
          margin: 0 auto;
        }
      }
      &:hover, &.show{
        background-color: $color-green;
        .link-title {
          text-decoration: none;
          @media (max-width: 992px) {
            color: $color-white;
          }
        }
      }
    }
    #navbarSupportedContent{
      width: 100%;
    }
    .navbar-nav{
      &.mr-auto{
        width: 100%;
        justify-content: space-around;
      }
      @media (max-width: 992px) {
        > .link-item{
          border-bottom: 0.1rem solid $color-white;
          &:last-child{
            border-bottom: none;
          }
        }
      }
    }
    @media (max-width: 992px) {
      #navbarSupportedContent{
        position: fixed;
        top: 8rem;
        background: -webkit-linear-gradient(left, $color-default-background 0%, $color-default-background 100%);
        background: -o-linear-gradient(left, $color-default-background 0%, $color-default-background 100%);
        background: -ms-linear-gradient(left, $color-default-background 0%, $color-default-background 100%);
        background: -moz-linear-gradient(left, $color-default-background 0%, $color-default-background 100%);
        background: linear-gradient(to right,$color-default-background 0%, $color-default-background 100%);
        left: 0;
        right: 0;
        bottom: 0;
        &.collapse.show{
          justify-content: flex-start;
          display: flex;
          flex-direction: column;
        }
        .navbar-nav{
          &.mr-auto{
            width: 100%;
            align-items: center;
            padding: 4rem 0;
          }
          &.sidebar-mobile{
            width: 100%;
            flex-direction: row;
            background-color: $color-green;
            position: relative;
            justify-content: space-around;
            li {
              margin: 0 1.5rem;

              a {
                text-decoration: none;

                .picto {
                  display: block;
                  text-align: center;
                  transition: all ease-in-out .5s;
                  i {
                    color: $color-white;
                    @include font-size(4);
                  }
                }

                .title {
                  display: block;
                  text-align: center;
                  p {
                    text-transform: uppercase;
                    color: $color-white;
                    @include font-size(1.8);
                  }
                }
                &:hover {
                  .picto {
                    transform: rotate(360deg);
                  }
                }
              }
            }
          }
        }
      }
      .navbar-toggler{
        border: none;
        font-size: 2rem;
        .navbar-toggler-icon{
          filter: invert(20%) sepia(0%) saturate(412%) hue-rotate(182deg) brightness(91%) contrast(92%);
          background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_2_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"> <g> <path d="M5.5,31.7h89c1.5,0,2.8-1.2,2.8-2.8s-1.2-2.8-2.8-2.8h-89c-1.5,0-2.8,1.2-2.8,2.8S4,31.7,5.5,31.7z"/> <path d="M94.5,47.3h-89c-1.5,0-2.8,1.2-2.8,2.8s1.2,2.8,2.8,2.8h89c1.5,0,2.8-1.2,2.8-2.8S96,47.3,94.5,47.3z"/> <path d="M94.5,68.3h-89c-1.5,0-2.8,1.2-2.8,2.8s1.2,2.8,2.8,2.8h89c1.5,0,2.8-1.2,2.8-2.8S96,68.3,94.5,68.3z"/> </g> </svg>');
        }
      }
    }
  }
  &.sticky {
    background-color: $color-white;
    box-shadow: 0 0 1.3rem rgba(0, 0, 0, .1);
    @media (max-width: 992px) {
      background: -webkit-linear-gradient(left, $color-default-background 0%, $color-default-background 100%);
      background: -o-linear-gradient(left, $color-default-background 0%, $color-default-background 100%);
      background: -ms-linear-gradient(left, $color-default-background 0%, $color-default-background 100%);
      background: -moz-linear-gradient(left, $color-default-background 0%, $color-default-background 100%);
      background: linear-gradient(to right,$color-default-background 0%, $color-default-background 100%);
      > .row{
        height: 100%;
      }
    }

    .link-item {
      padding: 3rem 2rem 4rem;
      .nav-link {
        color: $color-grey-black;
        @media (max-width: 992px) {
          color: $color-grey-black;
        }
      }
      .dropdown-menu{
        margin-top: 4.3rem;
      }
      &:hover, &.show{
        .link-title {
          color: $color-white;
        }
      }
    }
    .main-logo-left {
      img {
        &.sticky-logo {
          display: block;
          width: 22rem;
        }
      }
      @media (max-width: 992px) {
        margin-left: 1rem;
      }
    }
  }
}
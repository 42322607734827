.team-block{
  background-color: $color-white;
  .image-part{
    padding: 5.2rem 2.5rem 2.4rem;
    display: block;
  }
  .infos-part{
    display: flex;
    justify-content: space-between;
    .txt{
      padding-left: 2.5rem;
      .job{
        @include font-size(1.6);
        font-family: 'PT Serif', serif;
        color: $color-green;
        line-height: 1rem;
      }
    }
    .linkedin{
      width: 6rem;
      height: 6rem;
      background-color: $color-blue;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .link-linkedin{
        position: absolute;
        top: 0;bottom: 0;left: 0;right: 0;
      }
      img{
        width: 3rem;
      }
    }
  }
}
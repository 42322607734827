.testimony{
  .container-testimony-video{
    margin: 3rem 0;
    .container-testimony-video-youtube{
      position: relative;
      height: 55rem;
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 2rem;
      }
    }
  }
  .all-enterprise{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 10rem;
    .item-entreprise{
      width: 25%;
      text-align: center;
      padding: 1rem;
      @media (max-width: 768px) {
        width: 33%;
      }
      @media (max-width: 520px) {
        width: 50%;
      }
    }
  }
}
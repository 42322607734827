@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?gux1cd');
  src:  url('fonts/icomoon.eot?gux1cd#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?gux1cd') format('truetype'),
    url('fonts/icomoon.woff?gux1cd') format('woff'),
    url('fonts/icomoon.svg?gux1cd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: "\e909";
  color: #fff;
}
.icon-moins:before {
  content: "\e908";
}
.icon-fleche:before {
  content: "\e900";
}
.icon-fleche1:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e902";
}
.icon-map-marker:before {
  content: "\e903";
}
.icon-plus:before {
  content: "\e904";
}
.icon-timer:before {
  content: "\e905";
}
.icon-twitter:before {
  content: "\e906";
}
.icon-youtube:before {
  content: "\e907";
}

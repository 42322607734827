.contact{
  .tel{
    @include font-size(3);
    color: $color-green;
    font-family: 'PT Serif', serif;
  }
  .form-part{
    padding: 6.6rem 4.5rem 5rem;
  }
  .map-part{
    padding: 0;
    @media (max-width: 768px) {
      height: 50rem;
    }
    #elephant-map{
      height: 100%;
      width: 100%;
      .marker {
        background-image: url('../../images/mapbox-icon.png');
        background-size: cover;
        width: 48px;
        height: 53px;
      }
    }
  }
  .background-full-image{
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../images/structure/bg-contact.jpg') no-repeat center;
    background-size: cover;

  }
  .bloc-contact{
    margin-top: 3.5rem;
  }
}
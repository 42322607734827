.container-footer{
  position: relative;
  &:before{
    content: url($imagesPath + 'structure/motif2.svg');
    position: absolute;
    top: -105%;
    right: 12%;
    width: 15.5rem;
    z-index: 3;
    @media (max-width: 768px) {
      width: 10rem;
      top: -50%;
    }
    @media (max-width: 520px) {
      width: 10rem;
      top: -35%;
    }
  }
  .elephant-container{
    overflow: hidden;
    .elephant-footer{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
    }
  }
  p, a{
    @include font-size(1.4)
  }
  .colonne-footer{
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      > div{
        text-align: center;
        width: 50%;
        &:first-child{
          width: 100%;
          margin-bottom: 2rem;
        }
      }
    }

    > div{
      display: flex;
      flex-direction: column;
    }
  }
  .mention{
    margin: 3rem 0 3rem;
    @media (max-width: 768px) {
      text-align: center;
    }
    p, a{
      @include font-size(1.2)
    }
  }
  .rs-footer{
    display: flex;
    @media (max-width: 768px) {
      justify-content: center;
    }
    .rs-rounded{
      display: flex;
      align-content: center;
      justify-content: center;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .1);
      margin-top: 1rem;
      margin-left: 0.6rem;
      img{
        width: 2rem;
      }
    }
  }
}
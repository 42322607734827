.news-block{
  background-color: $color-grey-black;
  display: flex;
  min-height: 34rem;
  @media (max-width: 520px) {
    flex-direction: column;
  }
  .part-image{
    width: 63%;
    @media (max-width: 992px) {
      width: 55%;
    }
    @media (max-width: 520px) {
      width: 100%;
      height: 38rem;
    }
  }
  .part-txt{
    width: 37%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3.3rem 0 3.8rem;
    @media (max-width: 992px) {
      width: 45%;
    }
    @media (max-width: 520px) {
      width: 100%;
      padding: 3rem 3.3rem 3rem 3.8rem;
    }
    .title{
      @include font-size(2.6)
    }
    .category{
      position: relative;
      @include font-size(1.4);
      color: $color-white;
      font-family: 'PT Serif', serif;
      transition: all ease-in-out 500ms;
      z-index: 2;
      &:before{
        display: inline-block;
        content: '';
        width: 1.4rem;
        height: 1.4rem;
        background-color: $color-green;
        border-radius: 50%;
        margin-right: 0.5rem;
        vertical-align: sub;
      }
    }
  }
}
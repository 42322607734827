.btn-elephant{
  @include font-size(1.5);
  display: inline-block;
  padding: 2.3rem;
  margin: 2rem 0;
  position: relative;
  z-index: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;

  span{
    font-style: italic;
  }
  &:before{
    display: inline-block;
    font-family: 'icomoon', serif !important;
    content: '\e900';
    @include font-size(1.4);
    margin-right: 1rem;
  }
  &:after {
    transform: translateY(-50%);
    content: "";
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: $color-white;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    transition: 0.3s ease;
    opacity: 0.3;
  }

  &.hover-style:after ,&:hover:after {
    width: 100%;
    left: 0;
    border-radius: 3rem;
  }
  &.bg-btn-green{
    &:after{
      background-color: $color-green;
    }
  }
  &.bg-btn-grey-black{
    &:after{
      background-color: $color-grey-black;
    }
  }
  &.no-opacity{
    &:after{
      opacity: 1;
    }
  }
}

.collection-add-btn{
  @include font-size(1.3);
  display: inline-block;
  padding: 1rem 1.4rem;
  margin: 2rem 0;
  position: relative;
  z-index: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;

  span{
    font-style: italic;
    color: $color-white;
  }
  &:before{
    display: inline-block;
    font-family: 'icomoon', serif !important;
    content: '\e904';
    @include font-size(1.4);
    margin-right: 1rem;
  }
  &:after {
    transform: translateY(-50%);
    content: "";
    width: 100%;
    height: 4rem;
    border-radius: 3rem;
    background-color: $color-grey-black;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    transition: 0.3s ease;
    opacity: 1;
  }
}

.download-btn{
  @include font-size(1.3);
  display: inline-block;
  padding: 1rem 2rem;
  margin: 2rem 0;
  position: relative;
  z-index: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  
  span{
    font-style: italic;
    color: $color-white;
  }
  &:before{
    display: inline-block;
    font-family: 'icomoon', serif !important;
    content: '\e909';
    @include font-size(1.4);
    margin-right: 1rem;
  }
  &:after {
    transform: translateY(-50%);
    content: "";
    width: 100%;
    height: 4rem;
    border-radius: 3rem;
    background-color: $color-grey-black;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    transition: 0.3s ease;
    opacity: 1;
  }
  &:hover, &:focus{
    &:after{
      background-color: $color-green;
    }
  }
}
form{
  label, .col-form-label{
    color: $color-green;
    @include font-size(1.1);
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-family: 'PT Serif', serif;
  }
  input.form-control, textarea.form-control,
  input.form-control:focus, textarea.form-control:focus{
    background-color: $color-white;
    color: $color-grey-black;
    border: none;
    border-bottom: 0.2rem solid $color-grey-white;
    padding: 1rem 0;
    @include font-size(1.7);
    box-shadow: none;

    &::placeholder{
      @include font-size(1.7);
      color: $color-grey;
    }
  }
}
.spontaneous{
  input.form-control, textarea.form-control,
  input.form-control:focus, textarea.form-control:focus{
    background-color: $color-default-background;
  }
}


.custom-checkbox{
  //margin: .5rem 0 2.5rem;
  width: 100%;
  position: relative;
  display: block;
  padding-left: 0;
  input[type=checkbox]{
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -2rem;
    &:checked ~ label{
      &:before {
        border: 0.2rem solid $color-grey-white;
        background-color: $color-green;
      }
      &:after {
        transform: rotate(-45deg) scale(1);
      }
    }
    &:focus + label::before {
      outline: 0;
    }
  }
  label{
    position: relative;
    min-height: 3.4rem;
    display: block;
    padding-left: 3.2rem;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    color: $color-grey;
    font-family: 'Poppins', sans-serif;
    @include font-size(1.2);
    letter-spacing: normal;
    text-transform: none;

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-45%);
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 0.3rem 0;
      width: 2rem;
      height: 2rem;
      transition: transform 0.28s ease;
      border-radius: 0.3rem;
      background-color: #FFF;
      border: 0.1rem solid #d3d3d3;
    }
    &:after {
      content: '';
      display: block;
      width: 1rem;
      height: 0.5rem;
      border-bottom: 0.2rem solid #FFF;
      border-left: 0.2rem solid #FFF;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      position: absolute;
      top: 0.9rem;
      left: 0.6rem;
    }
  }
}

.collection-item{
  display: flex;
  .remove-collection{
    margin-right: 2rem;
    a{
      color: $color-grey-black;
    }
  }
}

.vich-file{
  .custom-file{
    position: inherit;
    .custom-file-input{
      opacity: 1;
      color: $color-grey-black;
    }
    .custom-file-label{
      display: none;
    }
  }
}

// Categorie filter
form[name="news_category_filter"]{
  width: 100%;
  #news_category_filter{
    .choice-news-category{
      display: flex;
      justify-content: center;
      input{
        display: none;
      }
      label{
        @include font-size(1.5);
        font-family: 'PT Serif', serif;
        letter-spacing: inherit;
        display: flex;
        padding: 1.5rem;
        margin: 2rem 0;
        position: relative;
        z-index: 0;
        border: none;
        box-shadow: none;
        background-color: $color-white;
        border-radius: 3rem;
        min-width: 20rem;
        text-align: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease;
        text-transform: none;
        &:hover{
          background-color: $color-green;
          color: $color-white;
        }
      }
      input:checked ~ label{
        background-color: $color-green;
        color: $color-white;
      }
    }
  }
}

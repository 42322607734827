.show-advert{
  .background-head-elephant{
    background-image: url('../../images/structure/elephant-fond.svg');
    background-repeat: no-repeat;
    background-position: right -25% top 7rem;
    background-size: 45%;
  }
  h1{
    color: $color-white;
  }
  div{
    color: $color-grey;
  }
  .form-opportunity{
    background-color: $color-white;
    padding: 2rem 1.5rem;
  }
  .alert.alert-success{
    color: $color-green;
  }
}